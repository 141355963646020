import { Spacer, Box, StackView } from '@telus-uds/components-web'

export type PlanFeatureProps = {
  heading: React.ReactNode
  icon: React.ReactNode
  description: React.ReactNode
  contentRatio?: number
}

const PlanFeature = ({ heading, icon, description, contentRatio = 4 }: PlanFeatureProps) => {
  return (
    <div data-testid="plan-feature-testid">
      <StackView direction="row" justifyContent="flex-start" alignItems="center">
        <Box flex={1} top={1}>
          {icon}
        </Box>
        <Box testID="plan-feature-content" flex={contentRatio} vertical={0}>
          <StackView>
            {heading}
            {description}
          </StackView>
        </Box>
      </StackView>
      <Spacer space={3} />
    </div>
  )
}

export default PlanFeature
