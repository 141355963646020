import { IPlanFeatureFields } from 'contentful-types'

import { BlockRenderer } from 'src/siteBuilder/renderer/contentful/BlockRenderer'
import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import PlanFeature from './PlanFeature'

const ContentfulPlanFeature = withContentful<IPlanFeatureFields>(PlanFeature, {
  heading: (props) => <BlockRenderer block={props.fields.heading} />,
  icon: (props) => <BlockRenderer block={props.fields.icon} />,
  description: (props) => <BlockRenderer block={props.fields.description} />,
})

export default ContentfulPlanFeature
